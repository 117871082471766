import { useEmployeeList } from "@/api/services/main/employee";
import { Pagination } from "@/components/elements/Pagination";
import { Table } from "@/components/elements/Table";
import { TopPage } from "@/components/elements/TopPage";
import { EmployeeListBtnActions } from "@/features/employee/components/EmployeeListBtnActions";
import { EMPLOYEE_FILTER, TEmployeeListWithFilter } from "@/features/employee/constants/employeeFilter";
import { EMPLOYEE_LIST_TAB, EMPLOYEE_LIST_TAB_LABEL, TEmployeeListTab } from "@/features/employee/constants/employeeListTab";
import { useGetOfferEmployeeQuantity } from "@/features/employee/hook/useGetOfferEmployeeQuantity";
import { useGetProcessingAndRetireEmployeeQuantity } from "@/features/employee/hook/useGetProcessingAndRetireEmployeeQuantity";
import { useGetWorkingAndLeftEmployeeQuantity } from "@/features/employee/hook/useGetWorkingAndLeftEmployeeQuantity";
import { useMakeEmployeeListColumns } from "@/features/employee/hook/useMakeEmployeeListColumns";
import { usePagination } from "@/hooks/usePagination";
import { useSelectionTable } from "@/hooks/useSelectionTable";
import { AppRoutes } from "@/routes/config";
import { button, divider, text } from "@/theme/colors";
import { getDynamicRoute } from "@/utils/url";
import { TabContext, TabList } from "@mui/lab";
import { Box, Stack, styled, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const EmployeeListPage = () => {
  const [searchParams] = useSearchParams();
  const searchTab = searchParams.get("tab");
  const [tab, setTab] = useState<TEmployeeListTab>(searchTab ? searchTab : EMPLOYEE_LIST_TAB.WORKING_AND_LEFT);
  const [filter, setFilter] = useState<TEmployeeListWithFilter>(EMPLOYEE_FILTER.WORKING_AND_LEFT);

  const navigate = useNavigate();
  const pagReq = usePagination();
  const { data, isLoading } = useEmployeeList({ filter: { ...filter, page: pagReq.page, perPage: pagReq.perPage } });
  const { quantity: offerEmployeeQuantity } = useGetOfferEmployeeQuantity();
  const { quantity: workingAndLeftQuantity } = useGetWorkingAndLeftEmployeeQuantity();
  const { quantity: processingAndRetireQuantity } = useGetProcessingAndRetireEmployeeQuantity();

  useEffect(() => {
    if (tab === EMPLOYEE_LIST_TAB.OFFER) {
      setFilter(EMPLOYEE_FILTER.OFFER);
    }
    if (tab === EMPLOYEE_LIST_TAB.WORKING_AND_LEFT) {
      setFilter(EMPLOYEE_FILTER.WORKING_AND_LEFT);
    }
    if (tab === EMPLOYEE_LIST_TAB.PROCESSING_AND_RETIRE) {
      setFilter(EMPLOYEE_FILTER.PROCESSING_AND_RETIRE);
    }
  }, [tab]);

  const columns = useMakeEmployeeListColumns(tab);

  const { table, rowSelectionKeys } = useSelectionTable({ columns, rows: data?.data ?? [], primaryKey: "id", pinningColumns: ["action"] });

  return (
    <>
      <TopPage title="特定技能外国人" backUrl={AppRoutes.home} />
      <EmployeeListWrapper gap={3}>
        <EmployeeListBtnActions selectedEmployeeIds={rowSelectionKeys} />
        <BoxTabWrapper>
          <TabContext value={tab}>
            <TabList
              onChange={(_, tab) => {
                setTab(tab);
                if (tab === EMPLOYEE_LIST_TAB.OFFER) {
                  navigate(getDynamicRoute(AppRoutes.employeeList, {}, `page=1&tab=${EMPLOYEE_LIST_TAB.OFFER}`));
                }
                if (tab === EMPLOYEE_LIST_TAB.WORKING_AND_LEFT) {
                  navigate(getDynamicRoute(AppRoutes.employeeList, {}, `page=1&tab=${EMPLOYEE_LIST_TAB.WORKING_AND_LEFT}`));
                }
                if (tab === EMPLOYEE_LIST_TAB.PROCESSING_AND_RETIRE) {
                  navigate(getDynamicRoute(AppRoutes.employeeList, {}, `page=1&tab=${EMPLOYEE_LIST_TAB.PROCESSING_AND_RETIRE}`));
                }
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: button.pressed,
                  height: "4px",
                },
              }}
              variant="fullWidth"
            >
              <TabWrapper label={`${EMPLOYEE_LIST_TAB_LABEL[EMPLOYEE_LIST_TAB.OFFER]} (${offerEmployeeQuantity}) `} value={EMPLOYEE_LIST_TAB.OFFER} />
              <TabWrapper
                label={`${EMPLOYEE_LIST_TAB_LABEL[EMPLOYEE_LIST_TAB.WORKING_AND_LEFT]} (${workingAndLeftQuantity}) `}
                value={EMPLOYEE_LIST_TAB.WORKING_AND_LEFT}
              />
              <TabWrapper
                label={`${EMPLOYEE_LIST_TAB_LABEL[EMPLOYEE_LIST_TAB.PROCESSING_AND_RETIRE]} (${processingAndRetireQuantity}) `}
                value={EMPLOYEE_LIST_TAB.PROCESSING_AND_RETIRE}
              />
            </TabList>
            <Box mt={2}>
              <Pagination totalPage={data?.pagination.totalPage} />
              <Box mt={1}>
                <Table table={table} isLoading={isLoading} />
              </Box>
            </Box>
          </TabContext>
        </BoxTabWrapper>
      </EmployeeListWrapper>
    </>
  );
};

const EmployeeListWrapper = styled(Stack)`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
`;

const BoxTabWrapper = styled(Box)`
  & .MuiTabs-flexContainer {
    justify-content: space-between;

    & .MuiButtonBase-root {
      text-transform: none;
      font-weight: bold;
      font-size: 18px;
      color: ${text.disable};
    }

    & .Mui-selected {
      color: ${button.pressed};
    }
  }
`;

const TabWrapper = styled(Tab)`
  border-bottom: 4px solid ${divider.high};
`;
