import { useGetRegionList } from "@/api/services/global/master";
import { fetchEmployeeInfoApi } from "@/api/services/main/employee";
import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { InputDatePicker } from "@/components/elements/InputDatePicker";
import { Modal } from "@/components/elements/Modal";
import { Select } from "@/components/elements/Select";
import { Table } from "@/components/elements/Table";
import { DATE_FORMAT } from "@/constants/datetime";
import { PLAIN_TEXT } from "@/features/regular-document/constants/paycheckPlainText";
import { useMakeRelatedColumn } from "@/features/regular-document/hooks/useMakeRelatedColumn";
import { usePayCheck } from "@/features/regular-document/providers/PayCheckProvider";
import { payrollSchema } from "@/features/regular-document/schema/payrollSchema";
import { TPayrollForm, TPayrollRecordForm } from "@/features/regular-document/types/payrollForm";
import { TPayrollRecord } from "@/features/regular-document/types/payrollRecord";
import { useTable } from "@/hooks/useTable";
import { divider } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { dayjs } from "@/utils/dayjs";
import { toNumber } from "@/utils/number";
import { pick } from "@/utils/object";
import { getFullName } from "@/utils/string";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";

type TPayrollDetailFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  payrollRecords: TPayrollRecord[];
  setNextEmployeePayroll: (currentEmployeeId: number) => void;
};

const getDefaultValues = (payrollRecords: TPayrollRecord[]): TPayrollForm | undefined => {
  const firstRecord = payrollRecords[0];
  if (!firstRecord) return undefined;
  return {
    ...pick(
      firstRecord,
      "no",
      "employeeFullName",
      "employeeBirthday",
      "employeeGender",
      "employeeResidenceCode",
      "employeeZipCode",
      "employeeAddress",
      "isChangeWorkingLocation",
      "isChangeDispatchPlace",
      "isChangeWorkingDetail",
      "hasComparisonEmployee",
    ),
    employeeNationalityId: firstRecord.employeeNationalityId?.toString(),
    records: payrollRecords.map((rc) => pick(rc, "month", "legalDeductionAmount", "netAmountPaid", "totalAmountPaid", "workingDayCount", "recordId")),
  };
};

export const PayrollDetailModal: FC<TPayrollDetailFormModalProps> = ({ isOpen, onClose, payrollRecords, setNextEmployeePayroll }) => {
  const { updatePayroll, updateMultiPayroll } = usePayCheck();
  const methods = useForm<TPayrollForm>({
    resolver: yupResolver(payrollSchema),
    defaultValues: getDefaultValues(payrollRecords),
    mode: "onBlur",
  });

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid },
  } = methods;

  const { employeeBirthday, records } = useWatch({ control });
  const firstRecord = useMemo(() => payrollRecords[0], []);
  const { update } = useFieldArray({ control, name: "records" });

  const saveData = (values: TPayrollForm) => {
    if (!firstRecord.employeeId) return;
    values.records.forEach((recordValue) => {
      const found = payrollRecords.find((rc) => recordValue.recordId === rc.recordId);
      if (!found) return;
      updatePayroll({ ...found, ...recordValue });
    });
    updateMultiPayroll(firstRecord.employeeId, {
      ...pick(
        values,
        "employeeFullName",
        "employeeBirthday",
        "employeeGender",
        "employeeResidenceCode",
        "employeeZipCode",
        "employeeAddress",
        "hasComparisonEmployee",
        "isChangeWorkingLocation",
        "isChangeDispatchPlace",
        "isChangeWorkingDetail",
      ),
      employeeNationalityId: toNumber(values.employeeNationalityId),
    });
  };

  const onSave = (values: TPayrollForm) => {
    saveData(values);
    onClose();
  };

  const onSaveAndNext = (values: TPayrollForm) => {
    saveData(values);
    setNextEmployeePayroll(firstRecord.employeeId);
  };

  const refetchInfo = async () => {
    if (!firstRecord.employeeId) return;
    const { data: employee } = await fetchEmployeeInfoApi(firstRecord.employeeId);
    setValue("employeeFullName", getFullName(pick(employee, "firstName", "middleName", "lastName")));
    setValue("employeeNationalityId", employee.nationalityId?.toString());
    setValue("employeeBirthday", dayjs(employeeBirthday).format(DATE_FORMAT) ?? "");
    setValue("employeeGender", employee.gender ?? "");
    setValue("employeeResidenceCode", employee.residenceCode ?? "");
    setValue("employeeZipCode", employee.zipCode ?? "");
    setValue("employeeAddress", employee.address ?? "");
  };

  const resetForm = () => {
    reset(getDefaultValues(payrollRecords));
  };

  const onChangeRecordField = (index: number, key: keyof TPayrollRecordForm, value: string) => {
    const found = getValues(`records.${index}`);
    if (!found) return;
    const updateData = { ...found, [key]: value } as TPayrollRecordForm;
    update(index, updateData);
  };

  const columns = useMakeRelatedColumn(onChangeRecordField);
  const { table } = useTable({ columns, rows: records ?? [] });

  const { isLoading: isLoadingRegion, nationalityOptions } = useGetRegionList();

  return (
    <Modal title={<Typography variant="body14Bold">フォームに入力</Typography>} isOpen={isOpen} onClose={onClose} enableFooter={false}>
      <Form methods={methods} onSubmit={onSave} schema={payrollSchema}>
        <FormWrapper>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormField control={control} name="no">
                <Input readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <Button size="sm" sx={{ mt: 2.5 }} onClick={refetchInfo}>
                更新
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormField control={control} name="employeeFullName">
                <Input readonly />
              </FormField>
            </Grid>

            <Grid item xs={6}>
              <FormField control={control} name="employeeNationalityId">
                <Select loading={isLoadingRegion} options={nationalityOptions} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField name="employeeBirthday">
                <InputDatePicker nonControl value={dayjs(employeeBirthday)} readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="employeeGender">
                <Select enumName="EGender" readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="employeeResidenceCode">
                <Input readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="employeeZipCode">
                <Input readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
              <FormField control={control} name="employeeAddress">
                <Input readonly />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="isChangeWorkingLocation">
                <Select options={changeOrNoChange} />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="isChangeWorkingDetail">
                <Select options={changeOrNoChange} />
              </FormField>
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="isChangeDispatchPlace">
                <Select options={changeOrNoChange} />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <Table table={table} />
            </Grid>
            <Grid item xs={6}>
              <FormField control={control} name="hasComparisonEmployee">
                <Select options={yesOrNo} />
              </FormField>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Stack paddingTop={4} flexDirection="row" justifyContent="space-between">
            <Button variant="outline" onClick={resetForm}>
              クリア
            </Button>
            <Stack direction="row" gap={2}>
              <Button type="submit" disabled={!isValid}>
                保存する
              </Button>
              <Button onClick={handleSubmit(onSaveAndNext)}>保存して次の外国人へ</Button>
            </Stack>
          </Stack>
        </FormWrapper>
      </Form>
    </Modal>
  );
};

const changeOrNoChange = [
  { label: PLAIN_TEXT.noChange, value: PLAIN_TEXT.noChange },
  { label: PLAIN_TEXT.change, value: PLAIN_TEXT.change },
];

const yesOrNo = [
  { label: PLAIN_TEXT.yes, value: PLAIN_TEXT.yes },
  { label: PLAIN_TEXT.no, value: PLAIN_TEXT.no },
];

const FormWrapper = styled(Box)`
  max-height: 80svh;
  overflow: overlay;
  margin-top: 16px;
  border: 1px solid ${divider.high};
  border-radius: ${rounded.sm};
  padding: 24px;
  gap: 24px;
`;
