import { TCheckUnreadResDto } from "@/api/services/main/information/dtos/check-unread.dto";
import { TGetInformationResDto } from "@/api/services/main/information/dtos/get-infomation.dto";
import {
  TGetInformationListFilterDto,
  TGetInformationListReqDto,
  TGetInformationListResDto,
} from "@/api/services/main/information/dtos/get-information-list.dto";
import { DEFAULT_PAGINATION_REQUEST } from "@/constants/pagination";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { clientApi } from "@/utils/api";
import { createUrlWithParams } from "@/utils/url";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const fetchInformationListApi = async (request: TGetInformationListReqDto): Promise<TGetInformationListResDto> => {
  const url = createUrlWithParams("/informations", request);
  const { data } = await clientApi.get<TGetInformationListResDto>(url);

  return data;
};

export const useInformationList = (filter: TGetInformationListFilterDto) => {
  const reqs = {
    ...DEFAULT_PAGINATION_REQUEST,
    // sorts: ["updated_at|DESC"] as TApiSortsReq[],
    ...filter,
  };

  return useInfiniteScroll([QUERY_KEYS.INFORMATION.FETCH_LIST, filter], (page) => {
    return fetchInformationListApi({ ...reqs, page });
  });
};

export const fetchInformationDetailApi = async (informationId: string | number): Promise<TGetInformationResDto> => {
  const { data } = await clientApi.get<TGetInformationResDto>(`/informations/${informationId}`);

  return data;
};

export const useInformationDetail = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.INFORMATION.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchInformationDetailApi(id) : undefined),
    enabled: Boolean(id),
  });
};

export const checkUnreadApi = async (): Promise<TCheckUnreadResDto> => {
  const rs = await clientApi.get("/informations/check-unread", { params: { types: ["system", "tenant"] } });
  return rs.data;
};

export const useCheckUnread = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.INFORMATION.CHECK_UNREAD],
    queryFn: checkUnreadApi,
  });
};
