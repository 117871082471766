/**
 * Adds zero padding to a number or string to reach the desired length.
 *
 * @param {number | string} num - The number or string to be padded.
 * @param {number} length - The desired length of the output string.
 * @returns {string} - The zero-padded number or string.
 */
export const zeroPad = (num: number | string, length: number): string => {
  const numStr = num.toString();
  const zerosNeeded = length - numStr.length;
  const zeros = "0".repeat(zerosNeeded);

  return zeros + numStr;
};

/**
 * Removes leading zeros from a string.
 *
 * @param {string} value - The string from which to remove leading zeros.
 * @returns {string} - The string without leading zeros.
 */
export const removeLeadingZero = (value: string): string => {
  return value.replace(/^0+/, "");
};

/**
 * Checks if a value is a number.
 *
 * @param {unknown} value - The value to check.
 * @returns {value is number} - True if the value is a number, false otherwise.
 */
export const isNumber = (value: unknown): value is number => {
  const valueNumber = Number(value);
  return !isNaN(valueNumber);
};

/**
 * Converts a value to a number. If the value is not a number, returns 0.
 *
 * @param {unknown} value - The value to convert.
 * @returns {number} - The converted number, or 0 if the value is not a number.
 */
export const toNumber = (value: unknown): number => {
  if (isNumber(value)) return Number(value);
  return 0;
};

/**
 * Floors a number to two decimal places.
 *
 * @param {number} value - The number to floor.
 * @returns {number} - The floored number with two decimal places.
 */
export const floorToTwoDecimals = (value: number): number => {
  return Number(value?.toFixed(2));
};
